<template>
  <div class="container-fluid full-section">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1>404</h1>
          <h4>Данный пользователь не найден</h4>
        </div>
        <div class="col-md-3 col-md-offset-1">
          <div><img class="img_" src="../../assets/images/container.png"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockedUser',
  metaInfo() {
    return {
      title: `Пользователь не найден - ЛУКМАРКЕТ`,
    }
  },
}
</script>

<style>
.row {
  min-height: 100vh;
  align-items: center;
}
</style>
